import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { getCurrentAuthUser } from '../utils/auth'
import endlessOptions from '../../static/img/home-page/EDS_Endless_Options_2024.jpg'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.updateLoggedStatus = this.updateLoggedStatus.bind(this)
    this.state = {
      userInfo: null,
      isLogged: false,
      wrapperClass: 'home-page'
    }
  }

  async updateLoggedStatus() {
   const username = await getCurrentAuthUser()
   let newLoggedStatus = false
   if (username) newLoggedStatus = true
   let wrapperClass = ''
   if(this.props.path === '/'){
     wrapperClass = 'home-page'
   }
   this.setState({ isLogged: newLoggedStatus, wrapperClass: wrapperClass })
  }

  componentDidMount() {
    this.updateLoggedStatus()
  }

  executeScroll = () => scrollToRef(this.myRef)

  render() {
    let loginRegister = <div className="register-banner">
      <div className="row">
        <div className="col-lg-12">
        <p><Link to="/profile" className="button" style={{
          textAlign: 'center',
          margin: '0px'
        }}>GO TO MY.EDS</Link></p>
        </div>
      </div>
    </div>

    if(!this.state.isLogged){
      loginRegister = <div className="register-banner">
        <div className="row">
          <div className="col-lg-3">
          <h2>REGISTER TODAY FOR MORE FEATURES!</h2>
          </div>
          <div className="col-lg-6">
          <p>Register now, or log in to your account to experience the benefits
          of working with the Elevator Design Studio. Registration is fast,
          secure, and the first step in beginning your creative journey.</p>
          </div>
          <div className="col-lg-3" style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column'
          }}>
            <p><Link to="/register" className="button" style={{
              textAlign: 'center',
              margin: '0px'
            }}>REGISTER NOW</Link></p>
            <p>Already registered? <Link to="/profile">Login now</Link></p>
          </div>
        </div>
      </div>
    }
    return(
      <Layout wrapperClass={this.state.wrapperClass}>
        <div className='hero full-image-hero' style={{
          backgroundImage: 'url('+endlessOptions+')',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          position: 'relative'
        }}>
          {loginRegister}
        </div>

      </Layout>
    )
  }
}


export default App
